<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
        <div class="flex a-center"
             slot="extra">
          <Icon custom="i-icon icon-jingshi"
                size="24"
                color="#FF9B77"></Icon>
          当前班次完成率计算方式：里程完成率：{{mileage}}%;
          <!-- 路径完成率：{{path}}% -->
          <Button type="text"
                  class="m-l-5"
                  @click.stop="onClickComplete">设置完成度计算</Button>
        </div>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex">
            <template v-if="currentTabIndex!==3">
              <Select v-model="selectType"
                      @on-change="onChangeSelectType"
                      placeholder="查询分类"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in selectTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
            </template>
            <template v-if="currentTabIndex===0">
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            search
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Input v-model="gridName"
                     placeholder="网格名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" />
              <Input v-model="planInputName"
                     placeholder="计划名称"
                     maxlength="30"
                     clearable
                     v-stringLimit
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===1">
              <Select v-model="carType"
                      clearable
                      label-in-value
                      placeholder="车辆类型"
                      class="m-r-10"
                      style="width:160px"
                      @on-change="onChangeCarType">
                <Option v-for="item in carTypeArr"
                        :value="item.name"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            search
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <Select v-model="workLevel"
                      clearable
                      label-in-value
                      placeholder="作业等级"
                      class="m-r-10"
                      style="width:160px"
                      @on-change="onChangeWorkLevel">
                <Option v-for="item in workLevelArr"
                        :value="item.levelId"
                        :key="item.levelId">{{ item.name }}</Option>
              </Select> -->
              <!-- <MyTreeSelect :value="gridName"
                            placeholder="网格名称"
                            clearable
                            :data="gridNameTreeArr"
                            search
                            checked
                            class="m-r-10"
                            style="width:160px"
                            @onCheckChange="onChangeGridName">
              </MyTreeSelect> -->
              <!-- <Select v-model="plan"
                      label-in-value
                      multiple
                      placeholder="计划名称"
                      class="m-r-10 plan-name"
                      style="width:320px"
                      @on-change="onChangePlanName">
                <Option v-for="item in planNameArr"
                        :value="item.jxqsPlanId"
                        :key="item.jxqsPlanId">{{ item.name }}</Option>
              </Select> -->
            </template>
            <template v-if="currentTabIndex===2">
              <Select v-model="carType"
                      clearable
                      label-in-value
                      placeholder="车辆类型"
                      class="m-r-10"
                      style="width:160px"
                      @on-change="onChangeCarType">
                <Option v-for="item in carTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <Select v-model="violateType"
                      clearable
                      placeholder="违规类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in violateTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
              <Select v-model="remoteCar"
                      filterable
                      clearable
                      placeholder="请输入车牌号"
                      :remote-method="remoteMethod"
                      :loading="remoteCarLoading"
                      class="m-r-10"
                      style="width:160px"
                      @on-query-change="onQueryChangeRemoteCar">
                <Option v-for="(option, index) in remoteCarList"
                        :value="option.id"
                        :key="option.id">{{option.name}}</Option>
              </Select>
              <!-- <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            :data="carTreeArr"
                            search
                            checked
                            @onCheckChange="onChangeCarNumber">
              </MyTreeSelect> -->
            </template>
            <template v-if="currentTabIndex===3">
              <Input v-model="operator"
                     clearable
                     placeholder="操作人"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <DatePicker :value="date"
                        type="daterange"
                        :start-date="new Date()"
                        placeholder="请选择时间范围"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:260px"
                        v-if="selectType===1"></DatePicker>
            <DatePicker :value="dateMonth"
                        type="month"
                        placeholder="选择月份"
                        @on-change="onChangeDate"
                        :options="dateOptions"
                        style="width:160px"
                        v-if="selectType===2"></DatePicker>
          </div>
          <div class="flex">
            <template v-if="currentTabIndex!==3">
              <template v-if="selectType===1">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                        :ghost="buttonIndex == 0 ? false : true"
                        @click.stop="onClickToday">今天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                        :ghost="buttonIndex == 1 ? false : true"
                        @click.stop="onClickYesterday">昨天</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                        :ghost="buttonIndex == 2 ? false : true"
                        @click.stop="onClickNearlyThreeDay">近三天</Button>
              </template>
              <template v-if="selectType===2">
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                        :ghost="buttonIndex == 3 ? false : true"
                        @click.stop="onClickCurrentMonth">本月</Button>
                <Button type="primary"
                        class="m-l-10"
                        v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                        :ghost="buttonIndex == 4 ? false : true"
                        @click.stop="onClickLastMonth">上月</Button>
              </template>
            </template>
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/mechanicalClean-work/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content"
         v-if="currentTabIndex == 2">
      <Echarts ref="echarts"
               height="368px"
               :options="options"
               :data="violateChartData"
               v-show="violateChartData.length"></Echarts>
      <div class="noData"
           v-show="violateChartData.length===0">
        <img src="../../../../assets/common/tubiaono.png">
        暂无数据
      </div>
    </div>
    <div class="content">
      <template v-if="currentTabIndex===0">
        <div>
          <Dropdown>
            <Button class="m-r-10"
                    v-if="checkPermi(['/admin/mechanicalClean-work/export'])">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
            <DropdownMenu slot="list">
              <DropdownItem name="part">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('part')">
                  <Button>
                    导出部分
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="now">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('now')">
                  <Button>
                    导出本页
                  </Button>
                </ExportTemplate>
              </DropdownItem>
              <DropdownItem name="all">
                <ExportTemplate :path="getExportPath"
                                :data="getExportData('all')">
                  <Button>
                    导出全部
                  </Button>
                </ExportTemplate>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div class="flex a-center j-between">
          <Tabs v-model="currentCarWorkTabIndex"
                class="tabs"
                key="child1"
                style="flex:1;">
            <TabPane v-for="item in carWorkTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentCarWorkTabIndex==0"></TabPane>
          </Tabs>
          <!-- <div v-show="currentCarWorkTabIndex===1"
               class="flex a-center">
            完成状态：
            <RadioGroup v-model="status"
                        @on-change="onChangeRadio">
              <Radio :label="0">
                全部
              </Radio>
              <Radio :label="3">
                已完成
              </Radio>
              <Radio :label="1">
                未完成
              </Radio>
            </RadioGroup>
          </div> -->
        </div>
      </template>
      <template v-if="currentTabIndex===1">
        <div class="flex a-center j-between">
          <Tabs v-model="currentGridWorkTabIndex"
                class="tabs"
                key="child2"
                style="flex:1;">
            <TabPane v-for="item in gridWorkTabArr"
                     :key="item.name"
                     :label="item.name"
                     :disabled="currentGridWorkTabIndex==0"></TabPane>
          </Tabs>
          <!-- <div v-show="currentGridWorkTabIndex===1"
               class="flex a-center">
            完成状态：
            <RadioGroup v-model="status"
                        @on-change="onChangeRadio">
              <Radio :label="0">
                全部
              </Radio>
              <Radio :label="1">
                已完成
              </Radio>
              <Radio :label="2">
                未完成
              </Radio>
            </RadioGroup>
          </div> -->
        </div>
      </template>
      <template v-if="currentTabIndex===2">
        <Tabs v-model="currentViolationTabIndex"
              class="tabs"
              key="child3">
          <TabPane v-for="item in violationTabArr"
                   :key="item.name"
                   :label="item.name"
                   :disabled="currentViolationTabIndex==0"></TabPane>
        </Tabs>
      </template>
      <div class="table">
        <Table ref="table"
               :width="tableWidth"
               :height="tableHeight"
               :columns="getColumns"
               :data="getData"
               border
               stripe
               :row-class-name="rowClassName"
               :loading="tableLoading"
               @on-selection-change="onChangeSelect"
               @on-sort-change="onChangeSort">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop=""
                    type="text">处理</Button>
          </template>
        </Table>
        <Page class="m-t-10 m-l-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
      </div>
    </div>

    <!-- 完成度设置 -->
    <GridFinishDegreeModal v-model="gridCompleteVisible"
                           :type.sync="gridCompleteType"
                           @onClickConfirm="getCompleteness"></GridFinishDegreeModal>

    <!-- 查看地址 -->
    <PositionDetailModal v-model="positionDetailVisible"
                         :address="positionAddress"
                         :lng="positionLng"
                         :lat="positionLat"></PositionDetailModal>

    <!-- 车辆作业情况 详情列表的弹窗 -->
    <MechanicalCleanPlanDetailModal v-model="mechanicalCleanPlanDetailVisible"
                                    :item.sync="mechanicalCleanPlanDetailItem"
                                    :workType="mechanicalCleanPlanDetailWorkType"
                                    :date="date"></MechanicalCleanPlanDetailModal>

    <!-- 查看网格 -->
    <GridDetailSmallModal v-model="gridDetailSmallVisible"
                          :dataId.sync="gridDetailSmallId"></GridDetailSmallModal>
  </div>
</template>

<script>
import { getCurrentMonth, getToday, getYesterday, getNearlyThreeDay, getLastMonth, formatSecond } from '@/utils/dateRangUtil'
import GridFinishDegreeModal from '@/components/product/admin/modal/work/GridFinishDegreeModal'
import MechanicalCleanPlanDetailModal from '@/components/product/admin/modal/work/MechanicalCleanPlanDetailModal'
import GridDetailSmallModal from '@/components/product/admin/modal/work/GridDetailSmallModal'
import { mapActions, mapGetters } from 'vuex';
import MyTreeSelect from '@/components/common/MyTreeSelect';
import PositionDetailModal from '@/components/product/main/template/PositionDetailModal';
import Echarts from '@/components/common/Echarts';
import ExportTemplate from '@/components/common/ExportTemplate'
import { checkPermi } from '@/utils/permission'
export default {
  components: {
    GridFinishDegreeModal,
    MechanicalCleanPlanDetailModal,
    GridDetailSmallModal,
    MyTreeSelect,
    PositionDetailModal,
    Echarts,
    ExportTemplate
  },
  data () {
    return {
      dateOptions: {
        disabledDate (date) {
          return date && date > new Date()
        }
      },
      currentTabIndex: 0,
      buttonIndex: null,
      tabArr: [
        {
          name: '网格作业统计'
        },
        {
          name: '车辆作业统计'
        },
        {
          name: '违规统计'
        },
        // {
        //   name: '操作记录'
        // },
      ],
      selectType: 1,
      selectTypeArr: [
        {
          id: 1,
          name: '按天查询'
        },
        {
          id: 2,
          name: '按月查询'
        },
      ],
      carType: '',
      carTypeName: '',
      remoteCar: '',
      remoteCarList: [],
      remoteCarLoading: false,
      // carTypeArr: [],
      carNumber: '',
      carNumberId: '',
      carWorkPlanId: '',
      workLevel: '',
      workLevelName: '',
      // workLevelArr: [],
      gridName: '',
      gridId: '',
      planInputName: '',
      plan: [],
      planName: [],
      planId: [],
      planNameArr: [],
      violateType: '',
      violateTypeArr: [],
      departId: '',
      departmentName: '',
      departmentId: '',
      operator: '',
      date: [],
      dateMonth: '',
      status: 0,
      currentCarWorkTabIndex: 0,
      carWorkTabArr: [
        {
          name: '作业汇总'
        },
        {
          name: '作业明细'
        }
      ],
      currentGridWorkTabIndex: 0,
      gridWorkTabArr: [
        {
          name: '作业汇总'
        },
        {
          name: '作业明细'
        }
      ],
      currentViolationTabIndex: 0,
      violationTabArr: [
        {
          name: '违规汇总'
        },
        {
          name: '违规明细'
        }
      ],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidth: 0,
      tableHeight: 0,

      tableLoading: false,
      columnsCarWorkSummary: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '计划名称',
          minWidth: 240,
          key: 'planName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              on: {
                click: () => {
                  this.onClickCarWorkName(row)
                }
              },
              style: {
                cursor: 'pointer'
              },
              // class: {
              //   line1: true
              // },
            },
              [
                h('p',
                  {
                    style: {
                      color: '#25bb96',
                    }
                  }, row.planNameList ? row.planNameList[0] : ''),
                h('p',
                  {
                    style: {
                      color: '#a8a8a8',
                    }
                  }, row.planNameList && row.planNameList.length > 1 ? row.planNameList.slice(1).join() : ''),
              ]);
          }
        },
        {
          title: '所属机构',
          minWidth: 130,
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '网格名称',
          minWidth: 60,
          key: 'gridName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarWorkDetailGrid(row)
                }
              }
            }, row.gridName);
          }
        },
        {
          title: '排班车辆',
          minWidth: 60,
          key: 'shiftCarNumbers',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('Tooltip',
              {
                props: {
                  content: row.shiftCarNumbers ? row.shiftCarNumbers.join() : '',
                  maxWidth: 200
                },
              },
              [
                h('span',
                  {
                    style: {
                      color: '#25bb96',
                      cursor: 'pointer'
                    },
                    class: {
                      line1: true
                    },
                    on: {
                      click: () => {
                        this.onClickCarWorkName(row)
                      }
                    }
                  }, row.shiftCarNumbers ? row.shiftCarNumbers.length + '辆车' : 0),
              ]
            );
          }
        },
        {
          title: '作业周期',
          width: 60,
          key: 'workCycle',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.workCycle + '天')
          }
        },
        {
          title: '实施计划天数',
          width: 90,
          key: 'planDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          width: 60,
          key: 'completeDays',
          tooltip: true,
          align: 'center',
        },
        {
          title: '未完成天数',
          width: 90,
          key: 'unCompleteDays',
          tooltip: true,
          align: 'center',
        },
        {
          title: '作业车辆',
          width: 80,
          key: 'competeCars',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('Tooltip',
              {
                props: {
                  content: row.completeCarNumbers ? row.completeCarNumbers.join() : '',
                  maxWidth: 200
                },
              },
              [
                h('span',
                  {
                    style: {
                      color: '#25bb96',
                      cursor: 'pointer'
                    },
                    class: {
                      line1: true
                    },
                    on: {
                      click: () => {
                        this.onClickCarWorkName(row)
                      }
                    }
                  }, row.completeCarNumbers ? row.completeCarNumbers.length + '辆车' : 0),
              ]
            );
          }
        },
        {
          title: '规划里程(km)',
          width: 90,
          key: 'planningMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗里程(km)',
          width: 90,
          key: 'onGuardMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗时长',
          width: 120,
          key: 'onGuardWorkTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗油耗(L)',
          width: 90,
          key: 'onGuardOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业里程(km)',
          width: 90,
          key: 'workMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          width: 120,
          key: 'workTime',
          tooltip: true,
          align: 'center',
        },
        {
          title: '作业油耗(L)',
          width: 90,
          key: 'workOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶里程(km)',
          width: 90,
          key: 'emptyMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶时长',
          width: 120,
          key: 'emptyWorkTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶油耗(L)',
          width: 90,
          key: 'emptyOil',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsCarWorkDetail: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 100,
          key: 'carNumber',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarWorkDetailTangci(row)
                }
              }
            }, row.carNumber);
          }
        },
        {
          title: '车辆类型',
          minWidth: 100,
          key: 'carTypes',
          tooltip: true,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          minWidth: 120,
          key: 'deptName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          minWidth: 120,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '网格名称',
          width: 70,
          key: 'gridName',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickCarWorkDetailGrid(row)
                }
              }
            }, row.gridName);
          }
        },
        {
          title: '作业周期',
          minWidth: 60,
          key: 'workCycle',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.workCycle + '天')
          }
        },
        {
          title: '完成状态',
          minWidth: 60,
          key: 'status',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.status == 3 ? '已完成' : '未完成')
          }
        },
        {
          title: '设定趟次',
          minWidth: 60,
          key: 'setupTangCi',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业趟次',
          minWidth: 60,
          key: 'workTangCi',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '违规次数',
        //   minWidth: 60,
        //   key: 'violationsNumber',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '报警次数',
        //   minWidth: 60,
        //   key: 'alarmNumber',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '规划里程(km)',
          width: 90,
          key: 'planningMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗里程(km)',
          width: 90,
          key: 'onGuardMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗时长',
          width: 120,
          key: 'onGuardWorkTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗油耗(L)',
          width: 90,
          key: 'onGuardOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业里程(km)',
          width: 90,
          key: 'workMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          minWidth: 110,
          key: 'workTime',
          tooltip: true,
          align: 'center',
        },
        {
          title: '作业油耗(L)',
          width: 90,
          key: 'workOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶里程(km)',
          width: 90,
          key: 'emptyMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶时长',
          width: 120,
          key: 'emptyWorkTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶油耗(L)',
          width: 90,
          key: 'emptyOil',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsGridWorkSummary: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          width: 80,
          key: 'carNumber',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickGridWorkName(row)
                }
              }
            }, row.carNumber);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'departmentName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业计划天数',
          width: 90,
          key: 'planDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          minWidth: 60,
          key: 'completeDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未完成天数',
          width: 80,
          key: 'unCompleteDays',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '违规次数',
        //   minWidth: 60,
        //   key: 'violationsNumber',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '报警次数',
        //   minWidth: 60,
        //   key: 'alarmNumber',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '行驶里程(KM)',
          width: 100,
          key: 'travelMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶时长',
          width: 120,
          key: 'travelTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶油耗(L)',
          width: 90,
          key: 'travelOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗里程(KM)',
          width: 100,
          key: 'onGuardMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗时长',
          width: 120,
          key: 'onGuardTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗油耗(L)',
          width: 90,
          key: 'onGuardOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业里程(KM)',
          width: 100,
          key: 'workMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          width: 120,
          key: 'workTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业油耗(L)',
          width: 90,
          key: 'workOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶里程(KM)',
          width: 100,
          key: 'emptyMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶时长',
          width: 120,
          key: 'emptyTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶油耗(L)',
          width: 90,
          key: 'emptyOil',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsGridWorkDetail: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carNumber',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickGridWorkCarname(row)
                }
              }
            }, row.carNumber);
          }
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'departmentName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '车辆类型',
          minWidth: 60,
          key: 'carType',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划名称',
          minWidth: 60,
          key: 'planName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '网格名称',
          minWidth: 60,
          key: 'gridName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '计划天数',
          minWidth: 60,
          key: 'planDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '完成天数',
          minWidth: 60,
          key: 'completeDays',
          tooltip: true,
          align: 'center'
        },
        {
          title: '设定趟次',
          minWidth: 60,
          key: 'setTangCi',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业趟次',
          minWidth: 60,
          key: 'workTangCi',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '出车时间',
        //   minWidth: 60,
        //   key: 'kaoqinName',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '收车时间',
        //   minWidth: 100,
        //   key: 'carStartTime',
        //   tooltip: true,
        //   align: 'center'
        // },
        {
          title: '规划里程(KM)',
          minWidth: 100,
          key: 'planMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶里程(KM)',
          width: 100,
          key: 'travelMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶时长',
          width: 90,
          key: 'travelTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '行驶油耗(L)',
          width: 90,
          key: 'travelOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗里程(Km)',
          width: 100,
          key: 'onGuardMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗时长',
          minWidth: 60,
          key: 'onGuardTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '在岗油耗(L)',
          width: 90,
          key: 'onGuardOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业里程(KM)',
          width: 100,
          key: 'workMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业时长',
          width: 90,
          key: 'workTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业油耗(L)',
          width: 90,
          key: 'workOil',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶里程(KM)',
          width: 100,
          key: 'emptyMileage',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶时长',
          width: 80,
          key: 'emptyTime',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶油耗(L)',
          width: 90,
          key: 'emptyOil',
          tooltip: true,
          align: 'center'
        },
        // {
        //   title: '报警次数',
        //   minWidth: 60,
        //   key: 'workKm',
        //   tooltip: true,
        //   align: 'center'
        // },
        // {
        //   title: '违规次数',
        //   minWidth: 60,
        //   key: 'invalidKm',
        //   tooltip: true,
        //   align: 'center'
        // },
      ],
      columnsViolationSummary: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickViolationCarName(row)
                }
              }
            }, row.carName);
          }
        },
        {
          title: '车型',
          minWidth: 60,
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '合计违规次数',
          width: 90,
          key: 'hjwgcs',
          tooltip: true,
          align: 'center'
        },
        {
          title: '空驶',
          minWidth: 60,
          key: 'ks',
          tooltip: true,
          align: 'center'
        },
        {
          title: '停留',
          minWidth: 60,
          key: 'tl',
          tooltip: true,
          align: 'center'
        },
        {
          title: '作业遗留',
          minWidth: 60,
          key: 'zyyl',
          tooltip: true,
          align: 'center'
        },
        {
          title: '速度限制',
          minWidth: 60,
          key: 'sdxz',
          tooltip: true,
          align: 'center'
        },
        {
          title: '水量监测',
          minWidth: 60,
          key: 'sljc',
          tooltip: true,
          align: 'center'
        },
        {
          title: '油量监测',
          minWidth: 60,
          key: 'yljc',
          tooltip: true,
          align: 'center'
        },
        {
          title: '离岗',
          minWidth: 60,
          key: 'lg',
          tooltip: true,
          align: 'center'
        },
        {
          title: '晚到',
          minWidth: 60,
          key: 'wd',
          tooltip: true,
          align: 'center'
        },
        {
          title: '未到岗',
          minWidth: 60,
          key: 'wdg',
          tooltip: true,
          align: 'center'
        },
        {
          title: '超时未完成',
          minWidth: 60,
          key: 'cswwc',
          tooltip: true,
          align: 'center'
        },
        {
          title: '非法倾倒',
          minWidth: 60,
          key: 'ffqd',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsViolationDetail: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '车牌号',
          minWidth: 60,
          key: 'carName',
          tooltip: true,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '车型',
          minWidth: 60,
          key: 'carTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '所属机构',
          minWidth: 60,
          key: 'depName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规类型',
          minWidth: 60,
          key: 'ruleTypeName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规时间',
          minWidth: 60,
          key: 'time',
          tooltip: true,
          align: 'center'
        },
        {
          title: '违规地点',
          minWidth: 60,
          key: 'managePhone',
          tooltip: true,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', {
              style: {
                color: '#25bb96',
                cursor: 'pointer'
              },
              on: {
                click: () => {
                  this.onClickAddress(row)
                }
              }
            }, '点击查看');
          }
        },
        {
          title: '违规内容',
          minWidth: 60,
          key: 'content',
          tooltip: true,
          align: 'center'
        },
      ],
      columnsOperation: [
        {
          type: 'selection',
          width: 45,
          align: 'center',
          fixed: 'left',
        },
        {
          title: '序号',
          width: 60,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '操作人',
          key: 'manageName',
          tooltip: true,
          align: 'center'
        },
        {
          title: '操作日期',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '修改前值',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
        {
          title: '修改后值',
          key: 'managePhone',
          tooltip: true,
          align: 'center'
        },
      ],
      data: [],
      selectArr: [],
      options: {
        legend: {},
        tooltip: {},
        xAxis: {
          type: 'category'
        },
        yAxis: {
          type: 'value'
        },
        dataset: {
          source: []
        },
        series: [
          {
            type: 'bar'
          }
        ]
      },
      violateChartData: [],
      // 完成度
      gridCompleteVisible: false,
      gridCompleteType: null,
      mileage: '',  // 里程
      path: '',      // 路径
      // 查看地址
      positionDetailVisible: false,
      positionAddress: '',
      positionLng: 0,
      positionLat: 0,
      // 车辆作业详情列表 作业趟次
      mechanicalCleanPlanDetailVisible: false,
      mechanicalCleanPlanDetailItem: null,
      mechanicalCleanPlanDetailWorkType: 0,
      // 查看网格
      gridDetailSmallVisible: false,
      gridDetailSmallId: '',
      // 表格数据
      carData: [],
      carDataDetail: [],
      gridData: [],
      gridDataDetail: [],
      violationData: [],
      violationDataDetail: [],
    };
  },
  watch: {
    currentTabIndex () {
      this.resetSearchData()
      this.onClickCurrentMonth()
      this.currentCarWorkTabIndex = 0
      this.currentGridWorkTabIndex = 0
      this.currentViolationTabIndex = 0
    },
    currentCarWorkTabIndex (newVal) {
      if (newVal == 0) {
        this.carNumber = ''
        this.carNumberId = ''
      }
      this.onClickSearch()
    },
    currentGridWorkTabIndex (newVal) {
      if (newVal == 0) {
        this.remoteCar = ''
        this.carNumber = ''
        this.carNumberId = ''
      }
      this.onClickSearch()
    },
    currentViolationTabIndex (newVal) {
      if (newVal == 0) {
        this.carNumber = ''
        this.carNumberId = ''
        this.remoteCar = ''
        this.departmentId = ''
        this.departmentType = ''
        this.departmentName = ''
      }
      this.onClickSearch()
    },
    // status () {
    //   this.onClickSearch()
    // },
  },
  computed: {
    ...mapGetters({
      'carTreeArr': 'getCarTreeList',
      'gridNameTreeArr': 'getGridTreeList',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
    carTypeArr () {
      let arr = [{
        id: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getCarTypeAllList)
    },
    workLevelArr () {
      let arr = [{
        levelId: 0,
        name: '全部'
      }]
      return arr.concat(this.$store.getters.getRoadAttributeAllList)
    },
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              columns = this.columnsCarWorkSummary
              break;
            case 1:
              columns = this.columnsCarWorkDetail
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              columns = this.columnsGridWorkSummary
              break;
            case 1:
              columns = this.columnsGridWorkDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentViolationTabIndex) {
            case 0:
              columns = this.columnsViolationSummary
              break;
            case 1:
              columns = this.columnsViolationDetail
              break;
            default:
              break;
          }
          break;
        case 3:
          columns = this.columnsOperation
          break;
        default:
          break;
      }
      return columns
    },
    getData () {
      let data = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              data = this.carData
              break;
            case 1:
              data = this.carDataDetail
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              data = this.gridData
              break;
            case 1:
              data = this.gridDataDetail
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentViolationTabIndex) {
            case 0:
              data = this.violationData
              break;
            case 1:
              data = this.violationDataDetail
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
    getExportPath () {
      let path = ''
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              path = this.$http.exportCarWorkJxzySummaryList
              break;
            case 1:
              path = this.$http.exportCarWorkJxzyDetailList
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return path
    },
  },
  mounted () {
    this.init()
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateCarTypeAllList',
      'updateCarTreeList',
      'updateRoadAttributeAllList',
      'updateGridTreeList',
      'updateDepartmentTreeList',
    ]),
    init () {
      this.tableWidth = window.innerWidth - 240
      this.tableHeight = window.innerHeight - 370
      window.onresize = () => {
        this.tableWidth = window.innerWidth - 240
        this.tableHeight = window.innerHeight - 370
      }
      this.updateCarTypeAllList()
      this.updateCarTreeList()
      this.updateRoadAttributeAllList()
      this.updateGridTreeList()
      this.updateDepartmentTreeList()
      this.getCompleteness()
      this.onClickCurrentMonth()
      this.getPlanList()
      this.getViolateTypeList()
    },
    // 获取完成度
    getCompleteness () {
      this.$store.dispatch('getTimeCompleteDetail', 3).then(res => {
        this.mileage = res.kmOkThreshold
        this.path = res.routeOkThreshold
      })
    },
    // 设置完成度
    onClickComplete () {
      this.gridCompleteVisible = true
      this.gridCompleteType = 3
    },
    rowClassName (row, index) {
      if (this.currentCarWorkTabIndex == 1) {
        if (row.status != 3) {
          return 'table-recovery-color'
        }
      }
    },
    // 获取计划列表
    getPlanList () {
      let params = {
        page: 1,
        size: 99999,
      }
      this.$http.getMechanicalPlanList(params).then(res => {
        this.planNameArr = res.result.plans
      })
    },
    // 获取违规类型列表
    getViolateTypeList () {
      this.$http.getWorkRuleItemList({ type: 3 }).then(res => {
        if (res.code === 200) {
          let arr = [{
            id: 0,
            name: '全部'
          }]
          this.violateTypeArr = arr.concat(res.result.map(item => {
            item.id = item.customName
            item.name = item.customName
            return item
          }))
        }
      })
    },
    // 今天
    onClickToday () {
      this.date = [getToday(), getToday()]
      this.onClickSearch()
      this.buttonIndex = 0
    },
    // 昨天
    onClickYesterday () {
      this.date = [getYesterday(), getYesterday()]
      this.onClickSearch()
      this.buttonIndex = 1
    },
    // 近三天
    onClickNearlyThreeDay () {
      this.date = getNearlyThreeDay()
      this.onClickSearch()
      this.buttonIndex = 2
    },
    // 本月
    onClickCurrentMonth () {
      this.date = getCurrentMonth()
      let time = getCurrentMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 3
    },
    // 上月
    onClickLastMonth () {
      this.date = getLastMonth()
      let time = getLastMonth()[0].split('-')
      this.dateMonth = time[0] + '-' + time[1]
      this.onClickSearch()
      this.buttonIndex = 4
    },
    // 查询分类回调
    onChangeSelectType (value) {
      if (value == 1) {
        this.date = getCurrentMonth()
      } else {
        let time = getCurrentMonth()[0].split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.dateMonth = time[0] + '-' + time[1]
        this.date[0] = time[0] + '-' + time[1] + '-01'
        this.date[1] = time[0] + '-' + time[1] + '-' + day
      }
    },
    // 车辆类型回调
    onChangeCarType (data) {
      if (data) {
        this.carType = data.value
        this.carTypeName = data.label
      } else {
        this.carType = ''
        this.carTypeName = ''
      }
    },
    // 选择车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    // 作业等级回调
    onChangeWorkLevel (data) {
      if (data) {
        this.workLevel = data.value
        this.workLevelName = data.label
      } else {
        this.workLevel = ''
        this.workLevelName = ''
      }
    },
    // 选择网格
    onChangeGridName (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type != 'depart') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.gridName = valueArr.join()
      this.gridId = idArr.join()
    },
    // 计划名称回调
    onChangePlanName (data) {
      let idArr = []
      let valueArr = []
      data.forEach(item => {
        idArr.push(item.value)
        valueArr.push(item.label)
      });
      this.planName = valueArr
      this.planId = idArr
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 点击车辆作业汇总表格中车辆名称
    onClickCarWorkName (row) {
      this.carWorkPlanId = row.planId
      this.currentCarWorkTabIndex = 1
    },
    // 点击车辆作业汇总表格中车辆完成天数
    onClickCarWorkFinish (row) {
      this.status = 3
      // this.carNumberId = row.carId
      this.carNumber = row.carNumbers
      this.currentCarWorkTabIndex = 1
    },
    // 点击车辆作业汇总表格中车辆未完成天数
    onClickCarWorkUnFinish (row) {
      this.status = 1
      // this.carNumberId = row.carId
      this.carNumber = row.carNumbers
      this.currentCarWorkTabIndex = 1
    },
    // 点击车辆作业详细表格中作业趟次
    onClickCarWorkDetailTangci (row) {
      this.mechanicalCleanPlanDetailItem = row
      this.mechanicalCleanPlanDetailWorkType = 2
      this.mechanicalCleanPlanDetailVisible = true
    },
    // 点击车辆作业详细表格中网格名称
    onClickCarWorkDetailGrid (row) {
      this.gridDetailSmallVisible = true
      this.gridDetailSmallId = row.gridId
    },
    // 点击车辆作业汇总表格中违规次数
    onClickViolate (row) {
      // this.carNumberId = row.carId
      this.carNumber = row.carNumbers
      this.currentTabIndex = 2
    },
    // 点击网格作业明细表格的车牌号
    onClickGridWorkCarname (row) {
      this.mechanicalCleanPlanDetailItem = row
      this.mechanicalCleanPlanDetailWorkType = 6
      this.mechanicalCleanPlanDetailVisible = true
    },
    // 点击网格作业情况表格中网格名称
    onClickGridWorkName (row) {
      this.gridId = row.gridId
      this.gridName = row.gridName
      this.currentGridWorkTabIndex = 1
      this.remoteCar = row.carId
    },
    // 点击网格作业情况表格中车辆完成天数
    onClickGridWorkFinish (row) {
      this.status = 1
      this.gridId = row.gridId
      this.gridName = row.gridName
      this.currentGridWorkTabIndex = 1
    },
    // 点击网格作业情况表格中车辆未完成天数
    onClickGridWorkUnFinish (row) {
      this.status = 2
      this.gridId = row.gridId
      this.gridName = row.gridName
      this.currentGridWorkTabIndex = 1
    },
    // 点击违规统计表格中车辆名称
    onClickViolationCarName (row) {
      this.carNumberId = row.carId
      this.carNumber = row.carName
      this.remoteCar = row.carId
      this.currentViolationTabIndex = 1
    },
    // 明细单选框回调
    onChangeRadio () {
      this.onClickSearch()
    },
    // 点击表格中的查看位置
    onClickAddress (row) {
      this.positionAddress = row.address
      this.positionLng = row.lng
      this.positionLat = row.lat
      this.positionDetailVisible = true
    },
    // 车牌号远程搜索
    remoteMethod (query) {
      if (query !== '') {
        this.remoteCarLoading = true;
        let params = {
          onlineStatus: '',
          type: '',
          itemType: '',
          name: query,
          company: '',
        }
        this.$http.getMapSearchCarList(params).then((res) => {
          if (res.code === 200) {
            this.remoteCarLoading = false
            let data = res.result
            this.remoteCarList = data.filter(item => item.name.toLowerCase().indexOf(query.toLowerCase()) > -1);
          } else {
            this.$Message.info(res.message)
            this.remoteCarLoading = false
            this.remoteCarList = []
          }
        })
      } else {
        this.remoteCarList = []
      }
    },
    // 车牌号远程搜索框内容改变时
    onQueryChangeRemoteCar (value) {
      if (!value) {
        this.remoteCarList = []
      }
    },
    // 查询
    onClickSearch () {
      if (this.currentTabIndex == 2) {
        this.getEcharts()
      }
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickCurrentMonth()
    },
    // 重置搜索条件
    resetSearchData () {
      // 车辆
      this.departmentType = ''
      this.carType = ''
      this.carTypeName = ''
      this.carNumber = ''
      this.carNumberId = ''
      this.remoteCar = ''
      // 网格
      this.workLevel = ''
      this.workLevelName = ''
      this.gridName = ''
      this.gridId = ''
      this.planName = []
      this.planId = []
      this.plan = []
      this.remoteCar = ''
      // 违规
      this.violateType = ''
      this.planInputName = ''
      this.departmentId = ''
      this.departmentName = ''

      this.status = 0
      this.date = []
      this.buttonIndex = null
      // this.currentTabIndex = 0
      switch (this.currentTabIndex) {
        case 0:
          this.currentCarWorkTabIndex = 0
          break;
        case 1:
          this.currentGridWorkTabIndex = 0
          break;
        case 2:
          this.currentViolationTabIndex = 0
          break;
        default:
          break;
      }
    },
    // 日期回调
    onChangeDate (date) {
      if (this.selectType == 1) {
        this.date = date
      } else {
        let time = date.split('-')
        let day = new Date(time[0], time[1], 0).getDate()
        this.date[0] = date + '-01'
        this.date[1] = date + '-' + day
      }
      this.buttonIndex = null
    },
    onChangeSelect (selection) {
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              this.selectArr = selection.map(item => item.planId)
              break;
            case 1:
              this.selectArr = selection.map(item => item.carId)
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              this.getCarWorkSummaryList()
              break;
            case 1:
              this.getCarWorkDetailList()
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentGridWorkTabIndex) {
            case 0:
              this.getGridWorkSummaryList()
              break;
            case 1:
              this.getGridWorkDetailList()
              break;
            default:
              break;
          }
          break;
        case 2:
          switch (this.currentViolationTabIndex) {
            case 0:
              this.getViolationSummaryList()
              break;
            case 1:
              this.getViolationDetailList()
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    },
    // 车辆汇总列表
    getCarWorkSummaryList () {
      let params = {
        // status: '',
        // carType: this.carType == 0 ? '' : this.carTypeName,
        // carNumber: this.carNumber,
        startDate: this.date[0],
        endDate: this.date[1],
        gridName: this.gridName,
        planName: this.planInputName,
        departmentId: this.departmentId,
        page: this.pageNum,
        size: this.pageSize,
      }
      this.tableLoading = true
      this.$http.getCarWorkJxzySummaryList(params).then(res => {
        this.carData = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 车辆明细列表
    getCarWorkDetailList () {
      let params = {
        // status: this.status,
        // carType: this.carType == 0 ? '' : this.carTypeName,
        // carNumber: this.carNumber,
        startDate: this.date[0],
        endDate: this.date[1],
        gridName: this.gridName,
        planName: this.planInputName,
        departmentId: this.departmentId,
        planId: this.carWorkPlanId,
        page: this.pageNum,
        size: this.pageSize,
      }
      this.tableLoading = true
      this.$http.getCarWorkJxzyDetailList(params).then(res => {
        this.carDataDetail = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 网格汇总列表
    getGridWorkSummaryList () {
      let params = {
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        departmentId: this.departmentId,
        carTypeName: this.carType,
        carId: this.remoteCar,
      }
      this.tableLoading = true
      this.$http.getGridWorkSummaryList(params).then(res => {
        this.gridData = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 网格明细列表
    getGridWorkDetailList () {
      let params = {
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        departmentId: this.departmentId,
        carTypeName: this.carType,
        carId: this.remoteCar,
      }
      this.tableLoading = true
      this.$http.getGridWorkDetailList(params).then(res => {
        this.gridDataDetail = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规统计汇总列表
    getViolationSummaryList () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.remoteCar,
        depid: this.departmentId,
        startDate: this.date[0],
        endDate: this.date[1],
        page: this.pageNum,
        size: this.pageSize,
        module: 2
      }
      this.tableLoading = true
      this.$http.getViolationSummaryList(params).then(res => {
        this.violationData = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规统计明细列表
    getViolationDetailList () {
      let params = {
        ruleTypeName: this.violateType == 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.carNumberId,
        depid: this.departmentId,
        startDate: this.date[0],
        endDate: this.date[1],
        gridId: '',
        page: this.pageNum,
        size: this.pageSize,
        module: 2
      }
      this.tableLoading = true
      this.$http.getCarViolationList(params).then(res => {
        this.violationDataDetail = res.result.list
        this.totalPage = res.result.total
        this.tableLoading = false
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 违规统计的图表
    getEcharts () {
      let params = {
        ruleTypeName: this.violateType === 0 ? '' : this.violateType,
        // ruleTypeId: '',
        carTypeId: this.carType == 0 ? '' : this.carType,
        carTypeName: this.carType == 0 ? '' : this.carTypeName,
        carId: this.remoteCar ? this.remoteCar.split('[')[0] : '',
        depid: this.departmentId,
        startDate: this.date[0],
        endDate: this.date[1],
        module: 2
      }
      this.$http.getViolationEcharts(params).then(res => {
        if (res.code === 200) {
          if (res.result.length) {
            let arr = [
              ['车辆名称', '车辆违规']
            ]
            this.violateChartData = arr.concat(res.result.map(item => {
              return [item.car_name, item.count]
            }))
          } else {
            this.violateChartData = []
          }
        }
      })
    },
    // 导出文件数据
    getExportData (value) {
      let data = {}
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentCarWorkTabIndex) {
            case 0:
              data = {
                startDate: this.date[0],
                endDate: this.date[1],
                gridName: this.gridName,
                planName: this.planInputName,
                departmentId: this.departmentId,

              }
              if (value == 'now') {
                let ids = this.getData.map(item => {
                  return item.planId
                })
                data.planIds = ids
              } else if (value == 'part') {
                data.planIds = this.selectArr
              }
              break;
            case 1:
              data = {
                startDate: this.date[0],
                endDate: this.date[1],
                gridName: this.gridName,
                planName: this.planInputName,
                departmentId: this.departmentId,
                planId: this.carWorkPlanId,
              }
              if (value == 'now') {
                let ids = this.getData.map(item => {
                  return item.carId
                })
                data.carIds = ids
              } else if (value == 'part') {
                data.carIds = this.selectArr
              }
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return data
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-recovery-color td {
    color: #a8a8a8;
  }
}
.plan-name {
  ::v-deep .ivu-select-selection {
    max-height: 32px;
    overflow: hidden;
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  overflow-y: auto;
  background-color: #f0f2f5;
  .topbar {
    background-color: #fff;
    .topbar-search {
      padding: 16px;
    }
  }
  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }
    .table {
      margin-top: 16px;
      flex: 1;
    }
    .noData {
      height: 368px;
      img {
        width: 172px;
        height: 100px;
      }
    }
  }
}
</style>